import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { init, SimpleMDE } from '@stoplight/simplemde';

import { Renderer } from '@platform/utils/markdown';

import MarkdownViewer from '../MarkdownViewer';

let inited = false;

class SimpleMdEditor extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    spellChecker: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    extraKeys: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      previewOn: _.isUndefined(props.preview) ? true : props.preview,
    };
  }

  componentWillMount() {
    const { id } = this.props;
    this.id = id || 'simplemde-editor-0';
  }

  componentDidMount() {
    this.createEditor();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.simplemde.value()) {
      this.simplemde.value(nextProps.value);
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.preview && this.props.preview !== nextProps.preview) {
      setTimeout(() => this.createEditor(), 0);
    }
  }

  togglePreview = () => {
    this.setState({ previewOn: !this.state.previewOn });
  };

  createEditor() {
    const { codeMirrorInstance, extraKeys, onChange, hideToolbar, ...options } = this.props;

    if (!inited) {
      init({
        codeMirrorInstance,
        marked: Renderer,
      });

      inited = true;
    }

    const defaultOptions = {
      element: document.getElementById(this.id),
      initialValue: this.props.value,
      status: false,
      // latest codemirror throwing error w spell checker
      // not worth fixing since we're getting rid of simplemde anyways
      spellChecker: false,
      toolbar: hideToolbar
        ? null
        : [
            'bold',
            'italic',
            'heading-1',
            'heading-2',
            'heading-3',
            '|',
            'quote',
            'code',
            'unordered-list',
            'ordered-list',
            '|',
            'link',
            'image',
            '|',
            {
              name: 'preview',
              action: () => {
                this.togglePreview();
              },
              className: 'fa fa-eye',
              title: 'Toggle Preview',
            },
          ],
    };

    this.simplemde = new SimpleMDE(Object.assign({}, defaultOptions, options));

    if (extraKeys) {
      // https://codemirror.net/doc/manual.html#keymaps
      this.simplemde.codemirror.setOption('extraKeys', extraKeys);
    }

    this.simplemde.codemirror.on('change', () => {
      onChange(this.simplemde.value());
    });

    this.simplemde.codemirror.on('blur', () => {
      onChange(this.simplemde.value(), { immediate: true });
    });

    this.simplemde.codemirror.setOption('theme', 'default');
  }

  renderEditor() {
    const { fill, className } = this.props;

    return (
      <div className={cn('SimpleMDE flex flex-col', className, { 'fill-space': fill })}>
        <textarea id={this.id} />
      </div>
    );
  }

  renderPreview() {
    return (
      <MarkdownViewer
        className="SimpleMDE-markdown ov-s h-full fill-space"
        value={this.props.value || 'preview will show here'}
        context={this.props.context}
      />
    );
  }

  render() {
    const { fill, hideToolbar } = this.props;

    return (
      <div
        className={cn('SimpleMDE-wrapper flex h-full', {
          'fill-space': fill,
          'hide-toolbar': hideToolbar,
        })}
      >
        <div
          className={cn('SimpleMDE-editor flex-1 h-full pos-r', {
            'w-full': !this.state.previewOn,
            'w-50': this.state.previewOn,
          })}
        >
          {this.renderEditor()}
        </div>

        {this.state.previewOn && (
          <div className="SimpleMDE-preview flex-1 w-50 pos-r">{this.renderPreview()}</div>
        )}
      </div>
    );
  }
}

export default SimpleMdEditor;
